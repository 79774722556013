
import TenancyValuations from "~/graphql/Portfolio/TenancyValuations.gql";
import TenancyArea from "~/graphql/Portfolio/TenancyArea.gql";
import Agents from "~/graphql/Portfolio/Agents.gql";
import ValuationDelete from "~/graphql/Portfolio/Mutations/ValuationDelete.gql";

import ViewValuations from "~/pages/portfolio/components/Valuations/ViewValuations.vue";
import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers";
export default {
  components: {
    ViewValuations,
  },

  props: {
    tenancyId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      filterAgentTypes: [],
    };
  },

  apollo: {
    tenancy: {
      query: TenancyValuations,
      variables() {
        return InputBuilder()
          .setId(this.tenancyId)
          .setFilter(this.filterAgentTypes.length ? { matches: [{ agent_type: ["in", this.filterAgentTypes] }] } : undefined)
          .build();
      },
      update(data) {
        return data.assetManagementTenancy;
      },
    },

    area: {
      query: TenancyArea,
      variables() {
        return {
          id: this.tenancyId,
        };
      },
      update(data) {
        return {
          area: data.assetManagementTenancy.area,
          areaUnit: data.assetManagementTenancy.areaUnit,
        };
      },
    },

    agents: {
      query: Agents,
      update(data) {
        return data.assetManagementAgents?.items ?? [];
      },
    },
  },

  computed: {
    loading() {
      return this.$apollo.queries.tenancy.loading || this.$apollo.queries.area.loading || this.$apollo.queries.agents.loading;
    },

    valuations() {
      return this.tenancy?.assetManagementValuations?.items ?? [];
    },

    data() {
      return {
        valuations: this.valuations,
        area: this.area,
        agents: this.agents,
      };
    },
  },

  methods: {
    refetch() {
      this.$apollo.queries.tenancy.refetch();
    },

    async deleteValuation(id) {
      await this.$apollo.mutate({
        mutation: ValuationDelete,
        variables: {
          id,
        },
      });

      this.refetch();
    },

    filterAgentTypesChange(newTypes) {
      this.filterAgentTypes = [...newTypes];
    },
  },
};
